import React, { useEffect, useState } from "react";
// import MyProfileHeader from '../../myProfileHeader';
// import MyFirstFlat from '../../myFirstFlat';
import "./../myProfile/myProfile.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from "primereact/tooltip";
import { checkShopListFlow } from "../../../utility";

function ReferFriend() {
  const [profile, setProfile] = useState({});
  const [deleteChange, setDeleteChange] = useState(false);
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const notify = (message: string) => toast(message);

  const [referral, setReferral] = useState({});

  const token = localStorage.getItem("token");

  const rand = Math.random();
  const base_url = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios({
      method: "get",
      url: `${base_url}/api/my_profile`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status != 401) {
          setProfile(response.data.data);
        }
      })
      .catch(function (error) {});
  }, [state]);

  useEffect(() => {
    axios({
      method: "get",
      url: `${base_url}/api/refer_friend`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.status != 401) {
          setReferral(response.data.data);
        }
      })
      .catch(function (error) {});
  }, []);

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/login");
  };
  const navigateToUrl = (url:string) => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toLowerCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());

    const URL = window.location.href.split("/");
    if (URL.length > 4) {
      navigate(`/shop/${finalResult}${url}`);
    } else {
      navigate(url);
    }
  } else {
    navigate(url);
}
} else {
  navigate(url);
}
  };
  // const profileData: Profile = {profile};

  // const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
  //     obj[key];

  // interface Profile {
  //     id: number;
  //     name: string;
  //     email: string;
  //     mobile: string;
  //     photo: string;
  // }

  const profileData: { [unit: string]: number } = profile;

  const refer: { [unit: string]: any } = referral;

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        notify("Referral link copied successfully");
      })
      .catch((err) => {
        notify("Unable to copy referral link");
      });
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>My Profile</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> My Profile</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-myprofile">
        <div className="container">
          <div className="row">
            <div className="col-md-12 custom-myprofile">
              <h1>My Profile</h1>
              <span className="float-end">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToUrl("/");
                  }}
                >
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  Back to home
                </a>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="user-profile">
                <div className="user-profile-details">
                  <span className="db-img">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToUrl("/editProfile");
                      }}
                    >
                      <i className="fas fa-pencil-alt" aria-hidden="true"></i>
                    </a>
                  </span>
                  <img
                    className="user-profile-img"
                    src={`${profileData.web_photo}?bust=${rand}`}
                  />
                  <h4>
                    <small>Hello,</small> {profileData.name}
                  </h4>
                </div>
                <div className="orders-account">
                  <div
                    className="myorders"
                    onClick={() => navigateToUrl("/myProfile")}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToUrl("/myProfile");
                      }}
                    >
                      <i className="fas fa-layer-group"></i>
                      <h4>My Profile</h4>
                    </a>
                  </div>
                  <div
                    className="myorders"
                    onClick={() => navigateToUrl("/myOrders")}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToUrl("/myOrders");
                      }}
                    >
                      <i className="fas fa-tag"></i>
                      <h4>My Orders</h4>
                    </a>
                  </div>
                  <div
                    className="myorders"
                    onClick={() => navigateToUrl("/myAddress")}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateToUrl("/myAddress");
                      }}
                    >
                      <i className="fas fa-map-marker-alt"></i>
                      <h4>My Address</h4>
                    </a>
                  </div>
                  {/* <div className="myorders">
                                        <button className="btn btn-danger remove_option m-sm-1" type="button" data-bs-toggle="modal" data-bs-target="#deleteprofile" data-id="1" onClick={() => setDeleteChange(!deleteChange)}><i className="fa fa-trash"></i></button>
                                        <h4>Delete Profile</h4>
                                    </div> */}
                  <div className="myorders">
                    <a
                      href="#"
                      onClick={(e) => {
                        handleLogout(e);
                      }}
                    >
                      <i className="fas fa-power-off"></i>
                      <h4>Logout</h4>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-details-right">
                <div className="profileinfo-header">
                  <h5>Referral Information</h5>
                </div>
                <div className="personal-info">
                  <label>Link:</label>
                  <p>
                    {/* <a
                      href={`${window.location.protocol}//${
                        window.location.host
                      }/login/${
                        refer.user_details && refer.user_details.ref_linkstr
                      }`}
                    > */}
                    <span className="text-underline font-bold color-theme">{`${
                      window.location.protocol
                    }//${window.location.host}/login/${
                      Object.keys(refer).length > 0 &&
                      refer.user_details.ref_linkstr
                    }`}</span>
                    {/* </a> */}
                    <Tooltip target=".fa-copy" />
                    <i
                      data-pr-tooltip="Copy referral link"
                      data-pr-position="top"
                      onClick={() => {
                        copyToClipboard(
                          `${window.location.protocol}//${
                            window.location.host
                          }/login/${
                            refer.user_details && refer.user_details.ref_linkstr
                          }`
                        );
                      }}
                      className="fa fa-copy"
                    ></i>
                  </p>
                </div>
                <div className="personal-info">
                  <label>Referral Count:</label>
                  <p>{refer.referral_count}</p>
                </div>
                <div className="personal-info">
                  <label>Total Referral Value:</label>
                  <p>{refer.total_referral}</p>
                </div>
                <div className="personal-info">
                  <label>Referral Remaining Value:</label>
                  <p>{refer.referral_remaining}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ReferFriend;
