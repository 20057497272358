import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { appstore,googleplay,paymethod1,paymethod2,paymethod3,paymethod4,qrscan,logo,payment_methods } from '../../assets/img'
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload
});

function FooterTwo() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const Url = window.location.host
  const blogUrl = `https://blog.${Url}`
  const [areaCovered, setAreaCovered] = useState<any | null>({})
  const [settings, setSettings] = useState<any>({});
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  const base_url = process.env.REACT_APP_BACKEND_URL;

  const settingsData: any = useSelector<any>(
    (state) => state?.settings
);

  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();

  // useEffect(() => {
  //   if (Object.keys(settings).length === 0 && settingsData == undefined) {
  //     axios({
  //       method: "get",
  //       url: `${base_url}/api/settings`,
  //       headers: {
  //         "Accept": "application/json",
  //         'Access-Control-Allow-Methods': 'GET, POST',
  //       },
  //     }).then((response) => {
  //       if (response.status != 401) {
  //         setSettings(response.data.data);
  //         dispatch(setSettingsAll(response.data.data));
  //         let URL = window.location.href.split("/");
  //           if (!URL.includes("shop")) {
  //             const faviconLink = document.getElementById(
  //               "favicon"
  //             ) as HTMLLinkElement;
  //             if (faviconLink) {
  //               faviconLink.href = response.data.data.WebsiteSettings.logo_path +
  //               response.data.data.WebsiteSettings.style_fav
  //             } else {
  //               console.error("Favicon link element not found");
  //             }
  //           }

  //       }
  //     }).catch(function (error) {

  //     })
  //   }
  // }, [])

  useEffect(() => {
    let URL = window.location.href.split("/");
    if (!URL.includes("shop")) {
      const faviconLink = document.getElementById(
        "favicon"
      ) as HTMLLinkElement;
      if (faviconLink) {
        faviconLink.href = settingsData.WebsiteSettings.logo_path +
        settingsData.WebsiteSettings.style_fav
      } else {
        console.error("Favicon link element not found");
      }
    }
  }, []);

  return (
    <div>
      {jsonData && (
        <style type="text/css">
          {`
            :root {
              --footer-background: #${jsonData?.theme_2?.home?.footer?.bg_color};
          }
          .footer {
            background: var(--footer-background) url(../img/home/footer-bg-temp-2.png) no-repeat;
            padding-top: 40px;
            color: #fff;
        }
          `}
        </style>
      )}
      <footer className="footer">
        <div className="container">

        {/* <div className="row footer-sectiontwo">
            <div className="col-md-12">
              <div className='gr-border'>
              <div className="social-links gr-border-grid">
                    <div className="col-md footer-social text-center">
                      <ul className="list-inline">
                      {jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.facebook
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                          ?.facebook?.facebook_link
                      }
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-facebook"></i>
                      Facebook
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.instagram
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                          ?.instagram?.instagram_link
                      }
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-instagram"></i>
                      Instagram
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.twitter
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                          ?.twitter?.twitter_link
                      }
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-twitter"></i>
                      Twitter
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.whatsapp
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                          ?.whatsapp?.whatsapp_link
                      }
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-whatsapp"></i>
                      Whatsapp
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.linkedin
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                          ?.linkedin?.linkedin_link
                      }
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-linkedin"></i>
                      Linkedin
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.youtube
                  ?.is_enable && (
                  <li>
                    <a
                      href={
                        jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                          ?.youtube?.youtube_link
                      }
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-youtube"></i>
                      Youtube
                    </a>
                  </li>
                )}
                        </ul>
                    </div>
              </div>
              </div>
            </div>
          </div> */}
          <div className="row footer-sectionthree">
            <div className="col-md col-sm-12">
            <div className="logo-footer text-start">
                <img
                  src={`${
                    Object.keys(settings).length > 0 &&
                    settings.WebsiteSettings.logo_path
                  }${
                    Object.keys(settings).length > 0 &&
                    settings.WebsiteSettings.style_logo_en
                  }`}
                  alt=""
                />
              </div>
              {jsonData?.theme_2?.home?.footer?.footer_section?.about_section
                ?.is_enable && (
                <p style={{
                  color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                }}>
                  {
                    jsonData?.theme_2?.home?.footer?.footer_section
                      ?.about_section?.text
                  }
                </p>
              )}
              <ul className="footer-social text-start footer-socialli">
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                  ?.facebook?.is_enable && (
                  <li>
                    <a
                      href={`https://${jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.facebook?.facebook_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                  ?.instagram?.is_enable && (
                  <li>
                    <a
                      href={`https://${jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.instagram?.instagram_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                  ?.whatsapp?.is_enable && (
                  <li>
                    <a
                      href={`https://${jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.whatsapp?.whatsapp_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-whatsapp"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                  ?.linkedin?.is_enable && (
                  <li>
                    <a
                      href={`https://${jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.linkedin?.linkedin_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-linkedin"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                  ?.youtube?.is_enable && (
                  <li>
                    <a
                      href={`https://${jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.youtube?.youtube_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                )}
                {jsonData?.theme_2?.home?.footer?.footer_section?.social_media
                  ?.twitter?.is_enable && (
                  <li>
                    <a
                      href={`https://${jsonData?.theme_2?.home?.footer?.footer_section?.social_media?.twitter?.twitter_link}`}
                      target="_blank" rel="noreferrer"
                      style={{
                        color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                      }}
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Explore</h4>
              <ul>
                {/* <li><a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#videomodal">How it works</a></li> */}
                <li><a href='/#services-section' style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>Services</a></li>
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4>Our company</h4>
              <ul>
                <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/aboutUs')}} style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>About Us</a></li>
                <li><a target='_blank' href={blogUrl} rel="noreferrer" style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>Blog</a></li>
                <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/areaCovered')}} style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>Locations</a></li>
                {/* <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/reviews')}}>Reviews</a></li> */}
              </ul>
            </div>
            <div className="col-md col-sm-12">
              <h4 style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_heading_color}`,
        }}>Call Now for Services!</h4>
              <ul>
                <li>
                  <a
                    href={`tel:${jsonData?.theme_2?.home?.footer?.footer_section?.callnow_for_Services_section?.mbl_no?.label}`}
                    style={{
                      color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    {/* {settingsData?.WebsiteSettings?.contact_t5} */}
                    {
                      jsonData?.theme_2?.home?.footer?.footer_section
                        ?.callnow_for_Services_section?.mbl_no?.label
                    }
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${jsonData?.theme_2?.home?.footer?.footer_section?.callnow_for_Services_section?.email?.value}`}
                    className="primary-text"
                    style={{
                      color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
                    }}
                  >
                    <i className="uil uil-envelopes me-2"></i>
                    {
                      jsonData?.theme_2?.home?.footer?.footer_section
                        ?.callnow_for_Services_section?.email?.label
                    }
                  </a>
                </li>
                <li>
                  <p style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>
                    {
                      jsonData?.theme_2?.home?.footer?.footer_section
                        ?.callnow_for_Services_section?.address?.text
                    }
                  </p>
                </li>
                {/* <li><a href='' className='primary-text'><i className='uil uil-location-pin-alt me-2'></i>Go to Location</a></li> */}
              </ul>
            </div>
          </div>
          <div className="row  footer-sectionfour">
            <div className="col-md-6 col-xs-12">
              <p className="footer-last" style={{
          color: `#${jsonData?.theme_2?.home?.footer?.section_paragraph_color}`,
        }}>
                {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en} © {year}. All rights reserved. | Powered by &nbsp;<a href="http://www.bestatservices.com" target="blank">www.bestatservices.com</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterTwo;