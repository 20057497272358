import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";

import {
  appstore,
  googleplay,
  heroimg,
  banner1, 
  banner2,
} from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { fetchPostalCodes } from "../../redux/Actions";
import { checkShopListFlow, setValue, getValue } from "../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import { DebounceInput } from "react-debounce-input";
import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
import HomeShopList from "../homeShopList";
import RebeccaShopList from "../rebecca/rebeccaShopList";

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    // .matches(UKPostalCode, "Enter a valid Pin code")
    // .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

const searchPinCodeFormSchemarebecca = yup.object().shape({
  pinCode: yup
    .string()
    // .matches(UKPostalCode, "Enter a valid Pin code")
    // .trim()
});

function SectionOneBannerThemeThree() {
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [coveredArea, setCoveredArea] = useState(false);
  const [areaData, setAreaData] = useState([]);
  const [selectedSuggestedAddress, setSelectedSuggestedAddress] =
    useState<any>(null);
  const [listOfSuggestedAddress, setListOfSuggestedAddress] = useState([]);
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const clientPostcode: any = settingsData?.postcode;
  const shopURL = window.location.href;
  useEffect(() => {}, [homedata]);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const navigateToCheckout = () => {
    if (checkShopListFlow()) {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toLowerCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());

    const URL = window.location.href.split("/");
    if (URL.length > 4) {
      navigate(`/shop/${finalResult}/productLists`);
    } else {
      navigate("/productLists");
    }
  }else {
    navigate("/checkout");
}
}else {
  navigate("/checkout");
}
  };

  const {
    handleSubmit: handlePinCodeSubmitrebecca,
    control: pinCodeFormControlrebecca,  
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchemarebecca),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });
  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });
  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    if (checkWithNewPin == false) {
      let checkclientPostcode:any = "";
      // if(shopURL.includes("rebeccabeauty")){
      //   checkclientPostcode = clientPostcode;
      // };
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  useEffect(() => {
    const existingAddressSuggestion = JSON.parse(
      localStorage.getItem("addressSuggestions") || "[]"
    );
    setListOfSuggestedAddress(existingAddressSuggestion);
  }, []);

  // useEffect(() => { 
  //   if(shopURL.includes("rebeccabeauty")){
  //   setPinCodeFormValue("pinCode", clientPostcode);
  //   dispatch(fetchPostalCodes(clientPostcode));
  //   setPostalCodeValue(clientPostcode);
  //   }
  // },[]);

  const handleChange = (e: any) => {
    setCoveredArea(false);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    setPinCodeFormValue("pinCode", updateValue);
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    setPinCodeFormValue("pinCode", searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
  };

  const submitAddressFromSuggestion = async (selected: any) => {
    setSelectedSuggestedAddress(selected);
    localStorage.setItem("postalCode", selected.postalCode);
    localStorage.setItem(
      "selectedAddressBeforeLogin",
      JSON.stringify(selected)
    );
    if (localStorage.getItem("token")) {
      // handle api
      const contact_name = JSON.parse(localStorage.getItem("userDetails")!).data
        ?.user_info.name;
      const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
        .data?.user_info.mobile;
      const address = selected?.value?.split(",");
      const payloadData = {
        address_type: "pickup",
        address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
          selected.postalCode
        )}"}`,
      };
      const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
      if (
        uniqueAddress.status ||
        uniqueAddress.message == "Address already exists"
      ) {
        if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
      } else {
        addressFormSetError("address", {
          type: "server",
          message: "Unable to set address try again later",
        });
      }
    } else {
      if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
    }
  };

  const submitAddressChange = async () => {
    const postalCode: any = localStorage.getItem("postalCode");
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      let addressSuggestions: any = [{ ...selectedAddress }];
      addressSuggestions[0].postalCode = postalCode;
      if (localStorage.getItem("addressSuggestions")) {
        const existingAddressSuggestion = JSON.parse(
          localStorage.getItem("addressSuggestions") || "[]"
        );
        addressSuggestions = addressSuggestions.concat(
          existingAddressSuggestion
        );
      }
      localStorage.setItem(
        "addressSuggestions",
        JSON.stringify(addressSuggestions)
      );
      setListOfSuggestedAddress(addressSuggestions);
      if (localStorage.getItem("token")) {
        // handle api
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        if (checkShopListFlow()) {
            navigate("/shopList");
          } else {
            navigate("/productLists");
          }
      }
    }
  };

  const fetchCoveredAreaCodes = async () => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    try {
      const response = await axios({
        method: "get",
        url: `${base_url}/api/areas_covered_postcodes`,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Methods": "GET, POST",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (response.data.Response.response_code == "1") {
        setAreaData(response.data.data);
      } else {
        return false;
      }
    } catch (error) {}
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const userInfo = JSON.parse(localStorage.getItem("userDetails")!);
    let shop 
    // if(shopURL.includes("rebeccabeauty")){
    //   const cartProduct = getValue('ProductsFrom')
    //   if(cartProduct == 1){
    //     setValue("cartInformationData", []);
    //   }
    //   localStorage.setItem("postalCode", JSON.stringify(clientPostcode));
    //   shop = await dispatch(
    //     fetchGetShopByPinCode(JSON.stringify(clientPostcode))
    //   );
    // }
    //  else {
      shop = await dispatch(
        fetchGetShopByPinCode(JSON.stringify(data.pinCode))
      );
    //  }
    if (shop) {
      setCoveredArea(false);
      if(shopURL.includes("rebeccabeauty")){
       //navigateToCheckout();
       if (userInfo == null) {
        navigate("/login");
      }
      else {
        navigate("/productLists");
      }       
      }else {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
        setCoveredArea(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
        await fetchCoveredAreaCodes();
        setCoveredArea(true);
      }
    }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
      await fetchCoveredAreaCodes();
        setCoveredArea(true);
    }
  };

  return (
    <>
      <div
        className="home_single_search"
        style={{
          background: `url(${homedata?.theme_3?.home?.section_1?.banner})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <div className="banner-search-wrap">
                <span className="banner-tag">
                  {homedata?.theme_3?.home?.section_1?.sub_heading_top}
                </span>
                <h1>{homedata?.theme_3?.home?.section_1?.title}</h1>
                <p id="bookNow" className="d-none d-md-block mb-4">
                  {homedata?.theme_3?.home?.section_1?.paragraph}
                </p>
                {shopURL.includes("rebeccabeauty") ? (
                  <RebeccaShopList/>
                  // <>
                  //   <form onSubmit={handlePinCodeSubmitrebecca(submitPostalForm)} >
                  //     <div className="formonlybtn">
                  //       <div
                  //         className={`${checkWithNewPin == false ? "d-none" : ""}`}
                  //       >
                  //         <small className="text-danger sh3-postvalidation">
                  //           {pinCodeFormError.pinCode?.message}
                  //         </small>
                  //         <div className="postcodeform">
                  //           <Controller
                  //             name="pinCode"
                  //             control={pinCodeFormControlrebecca}
                  //             render={({ field }) => (
                  //               <input
                  //                 type="text"
                  //                 id="postcode-input"
                  //                 {...field}
                  //                 placeholder={
                  //                   homedata?.theme_3?.home?.section_1?.btn_group
                  //                     ?.btn1?.placeholder
                  //                 }
                  //                 className={`ui-autocomplete-input ${
                  //                   pinCodeFormError.pinCode ? "invalid" : ""
                  //                 }`}
                  //                 onChange={handleChange}
                  //                 autoComplete="off"
                  //                 value={clientPostcode}
                  //               />
                  //             )}
                  //           />
                  //           <button
                  //             className="btn hover-btn"
                  //             id="book_now_btn"
                  //             type="submit"
                  //             style={{
                  //               background: `#${homedata?.theme_3?.home?.section_1?.btn_group?.btn1?.bg_color}`,
                  //             }}
                  //           >
                  //             {
                  //               homedata?.theme_3?.home?.section_1?.btn_group?.btn1
                  //                 ?.text
                  //             }
                  //           </button>
                  //           <div className="dropdown">
                  //             {postalCodeList
                  //               .filter((item: any) => {
                  //                 const searchTerm = postalCodeValue.toLowerCase();
                  //                 const mainValue = item.postcode.toLowerCase();

                  //                 return (
                  //                   searchTerm &&
                  //                   mainValue.startsWith(searchTerm) &&
                  //                   mainValue !== searchTerm
                  //                 );
                  //               })
                  //               .slice(0, 10)
                  //               .map((item: any) => (
                  //                 <div
                  //                   onClick={() => onSearch(item.postcode)}
                  //                   className="dropdown-row"
                  //                   key={item.postcode}
                  //                 >
                  //                   <i className="fas fa-map-marker-alt"></i>
                  //                   {item.postcode}
                  //                 </div>
                  //               ))}
                  //           </div>
                  //           {coveredArea && (
                  //                 <div className="dropdown">
                  //                   <div className="dropdown-heading">
                  //                     Postcode areas we currently covered
                  //                   </div>
                  //                   {areaData.map((item: any) => (
                  //                     <div
                  //                       onClick={() => {
                  //                         onSearch(item);
                  //                         setCoveredArea(false);
                  //                       }}
                  //                       className="dropdown-row"
                  //                       key={item}
                  //                     >
                  //                       <i className="fas fa-map-marker-alt"></i>
                  //                       {item}
                  //                     </div>
                  //                   ))}
                  //                 </div>
                  //               )}
                  //         </div>
                  //       </div>
                  //   </div>
                  // </form>
                  // </>
                 ) :
                 (
                  <div className="postcodeform-container">
                    <>
                    <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                      <div
                        className={`${checkWithNewPin == false ? "d-none" : ""}`}
                      >
                        <small className="text-danger postvalidation">
                          {pinCodeFormError.pinCode?.message}
                        </small>
                        <div className="postcodeform">
                          <Controller
                            name="pinCode"
                            control={pinCodeFormControl}
                            render={({ field }) => (
                              <input
                                type="text"
                                id="postcode-input"
                                {...field}
                                placeholder="Enter Postcode"
                                className={`ui-autocomplete-input ${
                                  pinCodeFormError.pinCode ? "invalid" : ""
                                }`}
                                onChange={handleChange}
                                autoComplete="off"
                              />
                            )}
                          />
                          <button
                            className="btn hover-btn"
                            id="book_now_btn"
                            type="submit"
                          >
                            {
                              homedata?.theme_3?.home?.section_1?.btn_group?.btn1
                                ?.text
                            }
                          </button>
                          <div className="dropdown">
                            {postalCodeList
                              .filter((item: any) => {
                                const searchTerm = postalCodeValue.toLowerCase();
                                const mainValue = item.postcode.toLowerCase();

                                return (
                                  searchTerm &&
                                  mainValue.startsWith(searchTerm) &&
                                  mainValue !== searchTerm
                                );
                              })
                              .slice(0, 10)
                              .map((item: any) => (
                                <div
                                  onClick={() => onSearch(item.postcode)}
                                  className="dropdown-row"
                                  key={item.postcode}
                                >
                                  <i className="fas fa-map-marker-alt"></i>
                                  {item.postcode}
                                </div>
                              ))}
                          </div>
                          {coveredArea && (
                                <div className="dropdown">
                                  <div className="dropdown-heading">
                                    Postcode areas we currently covered
                                  </div>
                                  {areaData.map((item: any) => (
                                    <div
                                      onClick={() => {
                                        onSearch(item);
                                        setCoveredArea(false);
                                      }}
                                      className="dropdown-row"
                                      key={item}
                                    >
                                      <i className="fas fa-map-marker-alt"></i>
                                      {item}
                                    </div>
                                  ))}
                                </div>
                              )}
                        </div>
                      </div>
                    </form>
                    <form>
                      <div
                        className={`adrsselectform ${
                          checkWithNewPin == true ? "d-none" : ""
                        }`}
                      >
                        <small className="text-danger postvalidation">
                          {addressFormError.address?.message}
                        </small>
                        <div className="adrsselectdd">
                          <Controller
                            name="address"
                            control={addressFormControl}
                            render={({ field }) => (
                              <Select
                                placeholder="Select your address"
                                {...field}
                                value={selectedAddress}
                                className={`${
                                  addressFormError.address ? "invalid" : ""
                                }`}
                                onChange={handleAddressChange}
                                onFocus={() => {
                                  if (
                                    getValuesAddressForm("address").value == ""
                                  ) {
                                    addressFormSetError("address", {
                                      type: "server",
                                      message: "Address is required to proceed",
                                    });
                                  } else {
                                    clearErrors();
                                  }
                                }}
                                options={areaOptions}
                              />
                            )}
                          />

                          <input
                            type="button"
                            value="Continue"
                            className="btn hover-btn"
                            onClick={submitAddressChange}
                          />
                        </div>
                        <div className="adrsselect-or">OR</div>
                        <div className="adrsselect-link">
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setCheckWithNewPin(true);
                            }}
                          >
                            Check with another Postcode
                          </a>
                        </div>
                      </div>
                    </form>
                    {listOfSuggestedAddress.length > 0 && (
                      <div className={`adrsselectform`}>
                        <small className="d-block text-center my-2">
                          Or, Select recent address
                        </small>
                        <div className="adrsselectdd">
                          <Swiper
                            spaceBetween={15}
                            navigation={true}
                            modules={[Navigation]}
                            pagination={{
                              el: ".swiper-pagination",
                              clickable: true,
                            }}
                            breakpoints={{
                              640: {
                                slidesPerView: 1,
                              },
                              768: {
                                slidesPerView: 1,
                              },
                              1024: {
                                slidesPerView: 2,
                              },
                            }}
                            className="slider-adrs"
                          >
                            {listOfSuggestedAddress.map(
                              (address: any, index: any) => {
                                return (
                                  <>
                                    <SwiperSlide key={index}>
                                      <div className="adrsgrid">
                                        {/* <i className="fas fa-arrow-alt-circle-right me-3"></i> */}
                                        <div
                                          className="adrsgriddv"
                                          key={index}
                                          onClick={() =>
                                            submitAddressFromSuggestion(address)
                                          }
                                        >
                                          {/* <i className="fas fa-map-marker-alt me-2"></i> */}
                                          <div className="adrs-label">
                                            {address.label}
                                            <span className="adrs-pcode">
                                              {address.postalCode}
                                            </span>
                                          </div>
                                        </div>
                                        {/* <i className="fas fa-map-marker-alt ms-auto"></i> */}
                                      </div>
                                    </SwiperSlide>
                                  </>
                                );
                              }
                            )}
                          </Swiper>
                          {/* <Select
                          placeholder="Select recent address"
                          value={selectedSuggestedAddress}
                          onChange={submitAddressFromSuggestion}
                          options={listOfSuggestedAddress}
                        /> */}
                        </div>
                      </div>
                    )}
                  </>
                  </div>
                 )}
                <>
                {shopURL.includes("rebeccabeauty") ? (
                  <></>
                )
                :(
                  <>
                {homedata?.theme_3?.home?.section_1?.mobile_link_section
                  ?.is_enable && (
                  <div className="banner-app mt-4">
                    <h4>
                      {
                        homedata?.theme_3?.home?.section_1?.mobile_link_section
                          ?.mob_title
                      }
                    </h4>
                    <ul className="banner-app-links">
                      <li>
                        <a
                          target="_blank"
                          href={
                            homedata?.theme_3?.home?.section_1
                              ?.mobile_link_section?.ios_link
                          } rel="noreferrer"
                        >
                          <img src={appstore} alt="" style={{maxHeight: "50px"}} />
                        </a>
                      </li>

                      <li>
                        <a
                          target="_blank"
                          href={
                            homedata?.theme_3?.home?.section_1
                              ?.mobile_link_section?.android_link
                          } rel="noreferrer"
                        >
                          <img src={googleplay} alt="" style={{maxHeight: "50px"}} />
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                </>
              )}
              </>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="heroimg">
                {homedata?.theme_3?.home?.section_1?.banner_type == "image" ? (
                  <img
                    src={homedata?.theme_3?.home?.section_1?.banner_two}
                    alt=""
                    className="img-fluid"
                  />
                ) : (
                  <>
                    <video
                      loop
                      autoPlay
                      className="img-fluid"
                      src={homedata?.theme_3?.home?.section_1?.banner_two}
                    ></video>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionOneBannerThemeThree;
