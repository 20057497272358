import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { notification1 } from '../../assets/img'
import '../notification/notification-box.css';

function Notification(props: any) {
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const base_url = process.env.REACT_APP_BACKEND_URL;

  // const [notificationList, setNotificationList] = useState([])

  // useEffect(() => {
  //   // setNotificationList(notifications)
  //   axios({
  //     method: "get",
  //     url: `${base_url}/api/user_notification_list`,
  //     headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
  //   }).then((response) => {
  //     if (response.status != 401) {
  //       setNotificationList(response.data.data);
  //     }
  //   }).catch(function (error) {
      
  //   })

  // }, [])

  return (

    <div className='notification notification-dropdown'>

          <div className="notification-header">
            <p><a href="#" onClick={(e) => { e.preventDefault(); navigate('/notifications') }} target="_blank" className="header-notification">Notification</a></p>
            {/* <i className="fa fa-cog float-end" aria-hidden="true"></i> */}
          </div>
          <div className="notification-items">
            {/* {props.isLoaded == true ? <> */}
            {props.notifications?.map((notification: any) =>
              <li role="presentation" className='presentations'>
                <div className="alert-dismissible nf-dismissible">
                  <div className="notification-content">
                    <img src={notification1} className="img-fluid" alt="" />
                    <div className="nf-content">
                      <p>{notification.title}</p>
                      <span>{notification.body}</span>
                    </div>
                  </div>
                </div>
              </li>
            )}
            {/* </> : <><p className='loader'>Loading...</p></>} */}
          </div>
          <p className="notify-seeall">
            <a href="#" onClick={(e) => { e.preventDefault(); navigate('/notifications') }} className='all-nitification' target="_blank">See All Notification</a>
          </p>

    </div>
  )
}

export default Notification;