import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { shopimg } from "../../assets/img";
import { fetchGetShopByPinCode, fetchRandomShopList } from "../../redux/Actions/checkoutPageActions";

function RebeccaShopList() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const settings: any = useSelector<any>((state) => state.settings);
  const shopsList: any = useSelector<any>(
    (state) => state.shopsList?.data
  );
  const shopURL = window.location.href;

  useEffect(() => {
    dispatch(fetchRandomShopList());  
  }, []);
  const handleShopClick = (currentShopHome: any, currentShopName: any) => {
    localStorage.setItem("shop_id", currentShopHome);
    localStorage.setItem("currentShopHome", currentShopHome);
    localStorage.setItem("currentShopName", currentShopName);
    const shopName = currentShopName;
    const resultOne = shopName.replace(/\s(.)/g, function ($1: string) {
      return $1.toLowerCase();
    });
    const resultTwo = resultOne.replace(/\s/g, "");
    const finalResult = resultTwo.replace(/^(.)/, function ($1: string) {
      return $1.toLowerCase();
    });
    navigate(`/shop/${finalResult}`);
  };

  const submitPostalForm = async (shop_id: any, postcode: any) => {
    localStorage.setItem("shop_id", shop_id);
    localStorage.setItem("postalCode", JSON.stringify(postcode));
    const userInfo = JSON.parse(localStorage.getItem("userDetails")!);
    let shop 
    // if(shopURL.includes("rebeccabeauty")){
    //   const cartProduct = getValue('ProductsFrom')
    //   if(cartProduct == 1){
    //     setValue("cartInformationData", []);
    //   }
    //   localStorage.setItem("postalCode", JSON.stringify(clientPostcode));
    //   shop = await dispatch(
    //     fetchGetShopByPinCode(JSON.stringify(clientPostcode))
    //   );
    // }
    //  else {
      // shop = await dispatch(
      //   fetchGetShopByPinCode(JSON.stringify(data.pinCode))
      // );
    //  }
    // if (shop) {
      // setCoveredArea(false);
      // if(shopURL.includes("rebeccabeauty")){
       //navigateToCheckout();
       if (userInfo == null) {
        navigate("/login");
      }
      else {
        navigate("/productLists");
      }       
      // }
    // }
  };

  return (
    <>      
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12">
            <div className="home-shop-hdr">
              {/* <NavLink className="ms-2" to={'./shoplist'}>View All <i className="fas fa-angle-right ms-2"></i></NavLink> */}
            </div>
          </div>
        </div>
        <div className="row">
          {shopsList?.shops?.slice(0, 2).map((item: any,index:number) => {
            return (
              <>
                <div key={index} className="col-md-6 d-flex">
                  <div className="shop-grid bg-white">
                    <div className="shop-img text-center bg-dark">
                      {item?.setting?.shop_logo_url ? <img className="p-3" src={item?.setting?.shop_logo_url || shopimg} alt="" /> : <img src={shopimg} alt="" />}
                      {/* <img src={item?.setting?.shop_logo_url || shopimg} alt="" /> */}
                    </div>
                    <div className="shop-details">
                      <h3
                        onClick={() => handleShopClick(item?.id, item?.name)}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.name}
                      </h3>
                      <p className="clr-grey shop-details-adrs" style={{fontSize: '12px'}}>
                        <i className="fas fa-map-marker-alt me-2"></i>
                        {item?.address?.text_address} - <strong style={{fontSize: '14px'}}>{item?.address?.postal_code?.postcode}</strong>
                      </p>
                      <ul className="d-none">
                        {item?.recent_products.map((services: any,index:number) => {
                          return (
                            <>
                              <li key={index}>
                                <div>
                                  {services?.groupcategory?.subcategory.name}
                                  <span className="clr-grey ms-4">
                                    <i className="far fa-clock me-2"></i>
                                    {services?.groupcategory?.process_time}hrs
                                  </span>
                                </div>
                                <div className="shop-price">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    services?.group_category_default_option_price?.price
                                  }
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                      <div className="shop-serv-link">
                        <a onClick={() => submitPostalForm(item?.id, item?.address?.postal_code?.postcode)} className="btn btn2" >Book Now<i className="fas fa-angle-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default RebeccaShopList;
