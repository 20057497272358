import axios from "axios";
import React, { useEffect, useState } from "react";
import { ElfsightWidget } from "react-elfsight-widget";
import { useSelector } from "react-redux";
import "../reviews/reviews.css";

const reviewList = [
  { name: "Google Reviews", active: true },
  { name: "Feedbacks on Service", active: false },
  { name: "Feedbacks on Driver", active: false },
];
function Reviews() {
  const [reviewListData, setReviewListData] = useState(reviewList);
  const [serviceReviewList, setServiceReviewList] = useState([]);
  const [driverReviewList, setDriverReviewList] = useState([]);
  const [profileImg, setProfileImg] = useState("");
  const [avatarImg, setAvatarImg] = useState("");
  const [widgetId, setWidgetId] = useState("");
  const state: any = useSelector((state) => state);

  const [service, setServiceVisible] = useState(false);
  const [driver, setDriverVisible] = useState(false);
  const [reviews, setReviewsVisible] = useState(true);

  const handleListData = (idx: any) => {
    const cloneArr = reviewListData.map((item) => {
      item.active = false;
      return item;
    });
    if (idx == 0) {
      setDriverVisible(false);
      setServiceVisible(false);
      setReviewsVisible(true);
      setTimeout(() => {
        const review = document.getElementById('eapps-google-reviews-' + widgetId);
        review?.firstChild?.lastChild?.remove();
      }, 5000);
    } else if (idx == 1) {
      setReviewsVisible(false);
      setDriverVisible(false);
      setServiceVisible(true);
    } else {
      setServiceVisible(false);
      setReviewsVisible(false);
      setDriverVisible(true);
    }
    cloneArr[idx].active = true;
    setReviewListData(cloneArr);
  };

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://admin.bestatlaundry.test";
  // useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${base_url}/api/reviews`,
  //     headers: {
  //       Accept: "application/json",
  //       "Access-Control-Allow-Methods": "GET, POST",
  //     },
  //   })
  //     .then((response) => {
  //       if (response.status != 401) {
  //         setServiceReviewList(response.data.data.service);
  //         setDriverReviewList(response.data.data.driver);
  //         setProfileImg(response.data.data.profile);
  //         setAvatarImg(response.data.data.avatar);
  //         setWidgetId(response.data.data.elfsight_widget_id);
  //         // setTimeout(() => {
  //         //   let review = document.getElementById('eapps-google-reviews-' + widgetId);
  //         //   review?.firstChild?.lastChild?.remove();
  //         // }, 5000);
  //       }
  //     })
  //     .catch(function (error) {

  //     });
  // }, [widgetId]);
  return (
    <div>
      <div className="revi-section">
        <div className="container">
          <div className="row">
            <div className="section-title text-center">
            </div>
            <div className="col-md-12">
              <div className="revs-tab">

                <ul className="nav nav-tabs" role="tablist">
                  {
                    reviewListData && reviewListData.map((item, idx) =>
                      <li role="presentation" className={` ${item.active && 'active'}`}><a href="#service" aria-controls="service" role="tab" data-bs-toggle="tab" onClick={() => handleListData(idx)}>{item.name}</a></li>
                    )
                  }
                </ul>
                {reviews &&
                !service &&
                !driver && (
                    widgetId !== undefined ?
                    <div
                      role="tabpanel"
                      className="tab-pane active mt-4"
                      id="driver">
                      <ElfsightWidget widgetID={widgetId} />
                    </div>
                    : <div className="text-center mt-5">No Reviews Found</div>
                  )}
                <div className="tab-content">
                  {!driver && !reviews && (
                    serviceReviewList.length > 0 ?
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="service"
                    >
                      <ul className="rev-section">
                        {serviceReviewList.map((service: any) => {
                          const stars = service.service_rating;
                          let count = 1;
                          const star = [];
                          for (let i = 1; i <= 5; i++) {
                            if (stars >= count) {
                              star.push(
                                <i className="fa fa-star selected"></i>
                              );
                            } else {
                              star.push(<i className="fa fa-star"></i>);
                            }
                            count++;
                          }
                          return (
                            <li>
                              <div>
                                {service ? (
                                  <>
                                    <img src={profileImg} />
                                    <div className="rev-info">
                                      <h2>{service.orders.user.name}</h2>
                                      <p>{service.service_comments}</p>
                                      <div className="rev-rating">
                                        <span>{service.service_rating}</span>
                                        {star}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <p>No Reviews Found.</p>
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    : <div className="text-center mt-5">No Reviews Found</div>
                  )}
                  {driver &&
                  !service &&
                  !reviews && (
                    driverReviewList.length > 0 ?
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="driver"
                    >
                      <ul className="rev-section">
                        {driverReviewList.map((driver: any) => {
                          const stars = driver.service_rating;
                          let count = 1;
                          const star = [];
                          for (let i = 1; i <= 5; i++) {
                            if (stars >= count) {
                              star.push(
                                <i className="fa fa-star selected"></i>
                              );
                            } else {
                              star.push(<i className="fa fa-star"></i>);
                            }
                            count++;
                          }
                          return (
                            <li>
                              <div>
                                {driver ? (
                                  <>
                                    <img src={profileImg} />
                                    <div className="rev-info">
                                      <h2>{driver.orders.user.name}</h2>
                                      <p>{driver.driver_comments}</p>
                                      <ul>
                                        <li>
                                          <img src={avatarImg} />
                                          <div>
                                            <h4>Driver</h4>
                                            <div className="rev-rating">
                                              <span>
                                                {driver.driver_rating}
                                              </span>
                                              {star}
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : (
                                  <p>No Reviews Found.</p>
                                )}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    : <div className="text-center mt-5">No Reviews Found</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Reviews;
